import PortalLayout from '@/layouts/PortalLayout'
export default {
  path: '/',
  redirect: '/home',
  component: PortalLayout,
  children: [
    {
      path: 'home',
      name: 'home',
      component: () => import(/* webpackChunkName: "router" */ '@/views/Home'),
      meta: {
        title: '首页',
      },
    },
    // {
    //   path: 'product',
    //   name: 'product',
    //   // redirect: '/product/sjsyszc',
    //   component: () =>
    //     import(/* webpackChunkName: "router" */ '@/views/yszc'),
    //   meta: {
    //     title: '产品中心',
    //   },
    // },
    {
      // path: '/product/sjsyszc',
      path: 'sjsgshm',
      name: 'sjsgshm',
      component: () =>
        import(/* webpackChunkName: "sjsyszc" */ '@/views/gshm'),
      meta: {
        title: '十角兽工商核名',
      },
    },
    {
      // path: '/product/sjsyszc',
      path: '/sjsyszc',
      name: 'sjsyszc',
      component: () =>
        import(/* webpackChunkName: "sjsyszc" */ '@/views/yszc'),
      meta: {
        title: '十角兽云上注册',
      },
    },
    {
      // path: '/product/sjsdzjqr',
      path: '/sjsdzjqr',
      name: 'sjsdzjqr',
      component: () =>
        import(/* webpackChunkName: "sjsdzjqr" */ '@/views/jzbs'),
      meta: {
        title: '十角兽代账机器人',
      },
    },
    {
      path: 'solution',
      name: 'solution',
      redirect: '/solution/bank',
      meta: {
        title: '解决方案',
      },
    },
    {
      path: '/solution/bank',
      name: 'solutionBank',
      component: () =>
        import(/* webpackChunkName: "solutionBank" */ '@/views/solution/bank'),
      meta: {
        title: '银行',
      },
    },
    {
      path: '/solution/customer',
      name: 'solutionCustomer',
      component: () =>
        import(
          /* webpackChunkName: "solutionCustomer" */ '@/views/solution/customer'
        ),
      meta: {
        title: '客户',
      },
    },
    {
      path: '/solution/partner',
      name: 'solutionPartner',
      component: () =>
        import(
          /* webpackChunkName: "solutionPartner" */ '@/views/solution/partner'
        ),
      meta: {
        title: '合伙人',
      },
    },
    {
      path: 'news',
      name: 'news',
      component: () => import(/* webpackChunkName: "router" */ '@/views/News'),
      meta: {
        title: '资讯动态',
      },
    },
    {
      path: 'news-detail',
      name: 'NewsDetail',
      component: () =>
        import(/* webpackChunkName: "router" */ '@/views/NewsDetail'),
      meta: {
        title: '动态详情',
      },
    },
    {
      path: 'case',
      name: 'case',
      component: () => import(/* webpackChunkName: "router" */ '@/views/Case'),
      meta: {
        title: '成功案例',
      },
    },
    {
      path: 'about',
      name: 'about',
      // component: () => import(/* webpackChunkName: "router" */ '@/views/About'),
      component: () =>
        import(/* webpackChunkName: "router" */ '@/views/about/index.vue'),
      meta: {
        title: '关于我们',
      },
    },
    {
      path: 'partner',
      name: 'partner',
      component: () =>
        import(/* webpackChunkName: "router" */ '@/views/partner'),
      meta: {
        title: '招募合伙人',
      },
    },
    {
      path: 'download',
      name: 'download',
      // redirect: '/product/sjsyszc',
      component: () =>
        import(/* webpackChunkName: "router" */ '@/views/download'),
      meta: {
        title: '下载中心',
      },
    },
  ],
}
